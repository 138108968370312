import { GraphQLClient } from 'graphql-request'

import firebaseCore from './firebase-core'
import analyticsCore from './analytics-core'
import {
  BRANCH_QUERY,
  GET_JOIN_REQUESTS_QUERY,
  GET_RECEIVER_PROFILE_QUERY,
  GET_TEAMS_FOR_BRANCH_QUERY,
  GET_USER_BRANCHES,
  INVITATIONS_QUERY,
  ME_QUERY,
  PAYOUTS_QUERY,
  SHORT_URL_QUERY,
  SIGN_UP_LINK,
  TEAM_QUERY,
} from '../graphql/queries'
import {
  ACCEPT_JOIN_REQUEST_MUTATION,
  ACCEPT_USER_BRANCH_INVITATION_MUTATION,
  CREATE_JOIN_REQUEST_MUTATION,
  CREATE_POOL_MUTATION,
  CREATE_RECEIVER_PROFILE_MUTATION,
  CREATE_SHARED_RECEIVER_PROFILE_MUTATION,
  CREATE_SHIFT_CONFIG,
  CREATE_TEAM_MUTATION,
  CREATE_USER_BRANCH_INVITATION_MUTATION,
  DELETE_POOL_MUTATION,
  DELETE_RECEIVER_PROFILE_MUTATION,
  DELETE_SHARED_RECEIVER_PROFILE_MUTATION,
  REJECT_JOIN_REQUEST_MUTATION,
  REJECT_USER_BRANCH_INVITATION_MUTATION,
  UPDATE_ME_MUTATION,
  UPDATE_POOL_MUTATION,
  UPDATE_RECEIVER_PROFILE_MUTATION,
  UPDATE_SHARED_RECEIVER_PROFILE_MUTATION,
  UPDATE_SHIFT_CONFIG,
  UPDATE_TEAM_MUTATION,
  UPDATE_USER_BRANCH_MUTATION,
  UPLOAD_SHIFT_DATA_MUTATION,
} from '../graphql/mutations'

import { JoinRequestType, UserBranch, UserType } from '../types/user-types'
import { BranchType, InvitationType, SignUpLinkInfo, TeamType } from '../types/api-types'
import emitter from './events-core'
import {
  BranchPayoutList,
  BranchPayoutsInput,
  CreateSharedReceiverProfileInput,
  CreateShiftConfigInput,
  Payout,
  Pool,
  QueryTeamArgs,
  ReceiverProfile,
  SharedReceiverProfile,
  ShiftConfig,
  ShiftDataUploadInput,
  ShiftDataUploadResult,
  Team,
  UpdatePoolInput,
  UpdateSharedReceiverProfileInput,
  UpdateShiftConfigInput,
  UpdateTeamInput,
} from '../graphql/graphql'

const ENDPOINT = process.env.APP_MANIFEST.extra.SERVER_API

// interval that lasts 15 seconds
const INTERVAL = 15 * 1000

interface CreateReceiverProfileInput {
  serviceId: string
  userId: string
  teamId: string
}
class API {
  private client = new GraphQLClient(ENDPOINT)

  private invitationsPollingInterval: NodeJS.Timer | undefined = undefined

  getLocationQRCode = async (branchId: string) => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        // Get the current firebase user
        const auth = firebaseCore.getAuth()
        // Get the current user's token
        const id = await auth?.currentUser?.getIdToken()

        // Setting the Authorization header
        this.client.setHeader('Authorization', `Bearer ${id}`)
        // Fetching the user data

        const response = await this.client.request(INVITATIONS_QUERY)
        resolve(response.me.Invitations)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/getLocationQRCode',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  getInvitations = async () => {
    return new Promise<(InvitationType | JoinRequestType)[]>(async (resolve, reject) => {
      try {
        // Get the current firebase user
        const auth = firebaseCore.getAuth()
        // Get the current user's token
        const id = await auth?.currentUser?.getIdToken()

        // Setting the Authorization header
        this.client.setHeader('Authorization', `Bearer ${id}`)
        // Fetching the user data

        const invitations = await this.client.request(INVITATIONS_QUERY)
        const joinRequests = await this.client.request(GET_JOIN_REQUESTS_QUERY)

        const inv = invitations.me.Invitations.map((item: InvitationType) => {
          return {
            ...item,
            type: 'invitation',
          }
        })
        const jr = joinRequests.me.UserBranches.map((ub: UserBranch) => {
          return ub.Branch.JoinRequests.map(item => {
            return {
              ...item,
              type: 'joinRequest',
            }
          })
        }).flat()

        const allInvitations = [...inv, ...jr]

        const invitationIDs: { [key: string]: true } = {}

        // Remove duplicates
        const filteredInvitations = allInvitations.filter(invite => {
          if (invitationIDs[invite.id]) {
            return false
          }
          invitationIDs[invite.id] = true
          return true
        })

        // Order allInvitations by createdAt from newest to older
        filteredInvitations.sort((a, b) => {
          const dateA = new Date(a.createdAt)
          const dateB = new Date(b.createdAt)
          return dateA.getTime() - dateB.getTime()
        })

        resolve(filteredInvitations)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/getInvitations',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  pollForInvitations = async () => {
    const invitations = await this.getInvitations()
    emitter.send('invitations', invitations)

    try {
      this.invitationsPollingInterval = setInterval(async () => {
        try {
          const invitationsAgain = await this.getInvitations()
          emitter.send('invitations', invitationsAgain)
        } catch (e) {
          console.error(e)
        }
      }, INTERVAL)
    } catch (error) {
      console.log(error)
    }
  }

  stopPollingForInvitations = () => {
    if (this.invitationsPollingInterval) {
      clearInterval(this.invitationsPollingInterval)
    }
  }

  acceptUserBranchInvitation = async (id: string) => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        // Get the current firebase user
        const auth = firebaseCore.getAuth()
        // Get the current user's token
        const authId = await auth?.currentUser?.getIdToken()

        // Setting the Authorization header
        this.client.setHeader('Authorization', `Bearer ${authId}`)

        const response = await this.client.request(ACCEPT_USER_BRANCH_INVITATION_MUTATION, { id })
        resolve(response.acceptUserBranchInvitation)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/acceptUserBranchInvitation',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  acceptJoinRequest = async (id: string) => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        // Get the current firebase user
        const auth = firebaseCore.getAuth()
        // Get the current user's token
        const authId = await auth?.currentUser?.getIdToken()

        // Setting the Authorization header
        this.client.setHeader('Authorization', `Bearer ${authId}`)

        const response = await this.client.request(ACCEPT_JOIN_REQUEST_MUTATION, { id })

        resolve(response.acceptJoinRequest)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/acceptJoinRequest',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  rejectUserBranchInvitation = async (id: string) => {
    return new Promise<{ success: boolean }>(async (resolve, reject) => {
      try {
        // Get the current firebase user
        const auth = firebaseCore.getAuth()
        // Get the current user's token
        const authId = await auth?.currentUser?.getIdToken()

        // Setting the Authorization header
        this.client.setHeader('Authorization', `Bearer ${authId}`)

        const response = await this.client.request(REJECT_USER_BRANCH_INVITATION_MUTATION, { id })

        resolve(response.rejectInvitation)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/rejectUserBranchInvitation',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  rejectJoinRequest = async (id: string) => {
    return new Promise<{ success: boolean }>(async (resolve, reject) => {
      try {
        // Get the current firebase user
        const auth = firebaseCore.getAuth()
        // Get the current user's token
        const authId = await auth?.currentUser?.getIdToken()

        // Setting the Authorization header
        this.client.setHeader('Authorization', `Bearer ${authId}`)

        const response = await this.client.request(REJECT_JOIN_REQUEST_MUTATION, { id })

        resolve(response.rejectInvitation)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/rejectJoinRequest',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  getManagerBranches = async () => {
    return new Promise<UserBranch[]>(async (resolve, reject) => {
      try {
        // Get the current firebase user
        const auth = firebaseCore.getAuth()
        // Get the current user's token
        const id = await auth?.currentUser?.getIdToken()

        // Setting the Authorization header
        this.client.setHeader('Authorization', `Bearer ${id}`)
        // Fetching the user data
        const data = await this.client.request(GET_USER_BRANCHES)

        const branches = data.me.UserBranches.filter((ub: UserBranch) => ub.role === 'manager')

        resolve(branches)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/getManagerBranches',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  validateSignUpLink = async (token: string) => {
    return new Promise<SignUpLinkInfo>(async (resolve, reject) => {
      try {
        const response = await this.client.request(SIGN_UP_LINK, { input: { token } })
        resolve(response.signUpLink)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/validateSignUpLink',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  getMe = async (): Promise<{ me: UserType }> => {
    return new Promise<{ me: UserType }>(async (resolve, reject) => {
      try {
        // Get the current firebase user
        const auth = firebaseCore.getAuth()
        // Get the current user's token
        const id = await auth?.currentUser?.getIdToken()

        // Setting the Authorization header
        this.client.setHeader('Authorization', `Bearer ${id}`)
        // Fetching the user data
        const data = await this.client.request(ME_QUERY)

        resolve(data)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/getMe',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  updateMe = async (data: Partial<UserType>) => {
    const variables = {
      ...data,
    }

    return new Promise<{ updateMe: UserType }>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const id = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${id}`)
        const response = await this.client.request(UPDATE_ME_MUTATION, { input: variables })
        resolve(response)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/updateMe',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  createInvitation = async (branchId: string) => {
    return new Promise<{ createJoinRequest: { id: string; state: string; createAt: string } }>(
      async (resolve, reject) => {
        try {
          const auth = firebaseCore.getAuth()
          const id = await auth?.currentUser?.getIdToken()

          this.client.setHeader('Authorization', `Bearer ${id}`)
          const response = await this.client.request(CREATE_JOIN_REQUEST_MUTATION, { input: { branchId } })

          resolve(response)
        } catch (err) {
          analyticsCore.track('error', {
            key: 'api-core/createInvitation',
            // @ts-ignore
            message: err.message || JSON.stringify(err),
          })
          reject(err)
        }
      },
    )
  }

  convertShortUrl = async (url: string) => {
    return new Promise<{ shortUrl: { destination: string; id: number; path: string; source: 'digital' | 'physical' } }>(
      async (resolve, reject) => {
        try {
          const auth = firebaseCore.getAuth()
          const id = await auth?.currentUser?.getIdToken()

          this.client.setHeader('Authorization', `Bearer ${id}`)
          const response = await this.client.request(SHORT_URL_QUERY, { url })

          resolve(response)
        } catch (err) {
          analyticsCore.track('error', {
            key: 'api-core/convertShortUrl',
            // @ts-ignore
            message: err.message || JSON.stringify(err),
          })
          reject(err)
        }
      },
    )
  }

  getBranch = async (slug: string) => {
    return new Promise<BranchType>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const id = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${id}`)
        const response = await this.client.request(BRANCH_QUERY, { input: { onboardingSlug: slug } })

        resolve(response.branch)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/getBranch',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  getTeamsForBranch = async (slug: string) => {
    return new Promise<TeamType[]>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const id = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${id}`)
        const response = await this.client.request(GET_TEAMS_FOR_BRANCH_QUERY, { input: { onboardingSlug: slug } })
        const teams = response.branch.Teams

        resolve(teams)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/getTeamsForBranch',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  getTeam = async (args: QueryTeamArgs) => {
    return new Promise<Team>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const id = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${id}`)
        const response = await this.client.request(TEAM_QUERY, args)
        const team = response.team

        resolve(team)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/getTeam',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  createReceiverProfile = async (data: CreateReceiverProfileInput) => {
    return new Promise<{ id: string }>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const id = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${id}`)
        const response = await this.client.request(CREATE_RECEIVER_PROFILE_MUTATION, { input: data })

        resolve(response.createReceiverProfile)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/createReceiverProfile',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  createUserBranchInvitation = async (data: { branchId: string; inviteeEmail: string; role: 'receiver' }) => {
    return new Promise<{ id: string }>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const id = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${id}`)
        const response = await this.client.request(CREATE_USER_BRANCH_INVITATION_MUTATION, { input: data })

        resolve(response.createUserBranchInvitation)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/createUserBranchInvitation',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  updateUserBranch = async (data: { id: string; internalId: string | null }) => {
    return new Promise<{ id: string }>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const id = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${id}`)
        const response = await this.client.request(UPDATE_USER_BRANCH_MUTATION, { input: data })

        resolve(response.updateUserBranch)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/updateUserBranch',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  updateReceiverProfile = async (data: { id: string; internalId: string | null }) => {
    return new Promise<{ id: string }>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const id = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${id}`)
        const response = await this.client.request(UPDATE_RECEIVER_PROFILE_MUTATION, { input: data })

        resolve(response.updateReceiverProfile)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/updateReceiverProfile',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  createTeam = async (data: { branchId: string; name: string; internalId?: string }) => {
    return new Promise<{ id: string; name: string }>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const id = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${id}`)
        const response = await this.client.request(CREATE_TEAM_MUTATION, { input: data })

        resolve(response.createTeam)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/createTeam',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  createPool = async (data: { teamId: string; distributionStrategy: 'equally' | 'per_hours_worked' }) => {
    return new Promise<{ id: string }>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const id = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${id}`)
        const response = await this.client.request(CREATE_POOL_MUTATION, { input: data })

        resolve(response.createPool)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/createPool',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  createSharedReceiverProfile = async (data: CreateSharedReceiverProfileInput) => {
    return new Promise<{ id: string }>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const id = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${id}`)
        const response = await this.client.request(CREATE_SHARED_RECEIVER_PROFILE_MUTATION, { input: data })

        resolve(response.createSharedReceiverProfile)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/createSharedReceiverProfile',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  uploadShiftData = async (data: ShiftDataUploadInput) => {
    return new Promise<ShiftDataUploadResult>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const id = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${id}`)
        const response = await this.client.request(UPLOAD_SHIFT_DATA_MUTATION, { input: data })

        resolve(response.uploadShiftData)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/uploadShiftData',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  updateTeam = async (data: UpdateTeamInput) => {
    return new Promise<Team>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const id = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${id}`)
        const response = await this.client.request(UPDATE_TEAM_MUTATION, { input: data })

        resolve(response.updateTeam)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/updateTeam',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  updatePool = async (data: UpdatePoolInput) => {
    return new Promise<Pool>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const id = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${id}`)
        const response = await this.client.request(UPDATE_POOL_MUTATION, { input: data })

        resolve(response.updatePool)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/updatePool',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  deletePool = async (teamId: string) => {
    return new Promise<{ success: boolean }>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const id = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${id}`)
        const response = await this.client.request(DELETE_POOL_MUTATION, { teamId })

        resolve(response.deletePool)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/deletePool',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  updateSharedReceiverProfile = async (data: UpdateSharedReceiverProfileInput) => {
    console.log({ data })
    return new Promise<SharedReceiverProfile>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const id = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${id}`)
        const response = await this.client.request(UPDATE_SHARED_RECEIVER_PROFILE_MUTATION, { input: data })
        console.log({ response })
        resolve(response.updateSharedReceiverProfile)
      } catch (err) {
        console.log({ err })
        analyticsCore.track('error', {
          key: 'api-core/updateSharedReceiverProfile',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  deleteSharedReceiverProfile = async (teamId: string) => {
    return new Promise<{ success: boolean }>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const id = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${id}`)
        const response = await this.client.request(DELETE_SHARED_RECEIVER_PROFILE_MUTATION, { teamId })

        resolve(response.deleteSharedReceiverProfile)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/deleteSharedReceiverProfile',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  deleteReceiverProfile = async (id: string) => {
    return new Promise<{ success: boolean }>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const token = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${token}`)
        const response = await this.client.request(DELETE_RECEIVER_PROFILE_MUTATION, { id })

        resolve(response.deleteReceiverProfile)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/deleteReceiverProfile',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  getPayouts = async (input: BranchPayoutsInput) => {
    return new Promise<BranchPayoutList>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const token = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${token}`)
        const response = await this.client.request(PAYOUTS_QUERY, { input })

        resolve(response.branchPayouts)
      } catch (err) {
        console.log(err)
        analyticsCore.track('error', {
          key: 'api-core/payouts',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  createShiftConfig = async (input: CreateShiftConfigInput) => {
    return new Promise<ShiftConfig>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const token = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${token}`)
        const response = await this.client.request(CREATE_SHIFT_CONFIG, { input })

        resolve(response.createShiftConfig)
      } catch (err) {
        console.log(err)
        analyticsCore.track('error', {
          key: 'api-core/createShiftConfig',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  updateShiftConfig = async (input: UpdateShiftConfigInput) => {
    return new Promise<ShiftConfig>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const token = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${token}`)
        const response = await this.client.request(UPDATE_SHIFT_CONFIG, { input })

        resolve(response.updateShiftConfig)
      } catch (err) {
        console.log(err)
        analyticsCore.track('error', {
          key: 'api-core/updateShiftConfig',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  getReceiverProfile = async (id: string) => {
    return new Promise<ReceiverProfile>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const token = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${token}`)
        const response = await this.client.request(GET_RECEIVER_PROFILE_QUERY, { input: id })

        resolve(response.receiverProfile)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/getReceiverProfile',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }
}

export default new API()
