import { useAuthState } from 'react-firebase-hooks/auth'
import React, { lazy, useEffect, useRef, useState } from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { NavigationContainer, useNavigationContainerRef } from '@react-navigation/native'

import apiCore from '../core/api-core'
import emitter from '../core/events-core'
import firebaseCore from '../core/firebase-core'
import analyticsCore from '../core/analytics-core'

// @ts-ignore
const Team = lazy(() => import('../screens/Team'))
// @ts-ignore
const Login = lazy(() => import('../screens/Login'))
// @ts-ignore
const SignUp = lazy(() => import('../screens/SignUp'))
// @ts-ignore
const Branch = lazy(() => import('../screens/Branch'))
// @ts-ignore
const Payouts = lazy(() => import('../screens/Payouts'))
// @ts-ignore
const Branches = lazy(() => import('../screens/Branches'))
// @ts-ignore
const EditTeam = lazy(() => import('../screens/EditTeam'))
// @ts-ignore
const CreateTeam = lazy(() => import('../screens/CreateTeam'))
// @ts-ignore
const UploadData = lazy(() => import('../screens/UploadData'))
// @ts-ignore
const LoadingScreen = lazy(() => import('../screens/LoadingScreen'))
// @ts-ignore
const BranchSettings = lazy(() => import('../screens/BranchSettings'))

export type AppStackParamList = {
  Login: undefined
  SignUp: undefined
  Payouts: undefined
  Branches: undefined
  UploadData: undefined
  Branch: { id: string }
  LoadingScreen: undefined
  CreateTeam: { id: string }
  BranchSettings: { id: string }
  Team: { branchId: string; teamId: string }
  EditTeam: { branchId: string; teamId: string }
}

const AppStack = createNativeStackNavigator<AppStackParamList>()

const Navigator = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false)
  const [user, loading, error] = useAuthState(firebaseCore.getAuth()!)
  const navigationStack = emitter.useEventListener('navigation-stack')

  const routeNameRef = useRef<string | undefined>()
  const navigationRef = useNavigationContainerRef()

  useEffect(() => {
    const getMe = async () => {
      try {
        const user = await apiCore.getMe()
        emitter.send('user-data', user.me)
        setIsLoading(false)
      } catch (error) {
        console.log(error)
      }
    }

    const userData = emitter.getCache('user-data')

    if (user?.uid && !userData) {
      getMe()
    } else if (!loading && !user?.uid) {
      setIsLoading(false)
    }
  }, [user?.uid, loading])

  useEffect(() => {
    const currentStack = emitter.getCache('navigation-stack')

    if (currentStack === undefined) {
      if (!loading && !error) {
        if (user) {
          emitter.send('navigation-stack', 'AppStack')
        } else {
          emitter.send('navigation-stack', 'AuthStack')
        }
      }
    }
  }, [user, loading, error])

  useEffect(() => {
    if (error) {
      analyticsCore.track('error', {
        key: 'auth-error',
        message: error?.message || JSON.stringify(error),
      })
    }
  }, [error])

  const onNavigationStateChange = () => {
    const previousRouteName = routeNameRef.current
    const currentRouteName = navigationRef?.getCurrentRoute()?.name

    if (currentRouteName && previousRouteName !== currentRouteName) {
      routeNameRef.current = currentRouteName
      analyticsCore.track('screen-tracking', { screen: currentRouteName })
    }
  }

  if (!navigationStack || loading || isLoading) {
    return <LoadingScreen />
  }

  const getStack = () => {
    if (navigationStack === 'AppStack' && user?.uid) {
      return (
        <>
          {/* @ts-ignore */}
          <AppStack.Screen name="Branches" component={Branches} options={{ title: 'Uptip - Branches' }} />
          <AppStack.Screen name="Branch" component={Branch} options={{ title: 'Uptip - Branch' }} />
          <AppStack.Screen
            name="BranchSettings"
            component={BranchSettings}
            options={{ title: 'Uptip - Branch Settings' }}
          />
          <AppStack.Screen name="Team" component={Team} options={{ title: 'Uptip - Team' }} />
          <AppStack.Screen name="Payouts" component={Payouts} options={{ title: 'Uptip - Payouts' }} />
          <AppStack.Screen name="EditTeam" component={EditTeam} options={{ title: 'Uptip - Edit Team' }} />
          <AppStack.Screen name="CreateTeam" component={CreateTeam} options={{ title: 'Uptip - Create Team' }} />
          <AppStack.Screen name="UploadData" component={UploadData} options={{ title: 'Uptip - Upload Data' }} />
        </>
      )
    } else if (navigationStack === 'AuthStack' && !user?.uid) {
      return (
        <>
          <AppStack.Screen name="Login" component={Login} options={{ title: 'Uptip - Login' }} />
          <AppStack.Screen name="SignUp" component={SignUp} options={{ title: 'Uptip - Sign Up' }} />
        </>
      )
    } else {
      return (
        <>
          <AppStack.Screen name="LoadingScreen" component={LoadingScreen} options={{ title: 'Uptip - Loading' }} />
        </>
      )
    }
  }

  return (
    <NavigationContainer
      linking={{
        enabled: true,
        prefixes: [],
        config: {
          screens: {
            // @ts-ignore
            Login: 'login',
            SignUp: 'signup',
            Payouts: 'payouts',
            Branches: 'branches',
            UploadData: 'upload-data',
            Branch: { path: 'branch/:id' },
            Team: { path: 'team/:branchId/:teamId' },
            CreateTeam: { path: 'create-team/:id' },
            BranchSettings: { path: 'branch-settings/:id' },
            EditTeam: { path: 'edit-team/:branchId/:teamId' },
          },
        },
      }}
      ref={navigationRef}
      onStateChange={() => onNavigationStateChange()}
      onReady={() => (routeNameRef.current = navigationRef?.getCurrentRoute()?.name)}>
      <AppStack.Navigator screenOptions={{ headerShown: false }}>
        <>{getStack()}</>
      </AppStack.Navigator>
    </NavigationContainer>
  )
}

export default Navigator
