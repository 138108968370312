import React, { Suspense } from 'react'

import Navigator from './src/navigation/Navigator'
import LoadingScreen from './src/screens/LoadingScreen'

import analyticsCore from './src/core/analytics-core'
import firebaseCore from './src/core/firebase-core'

analyticsCore.init()
firebaseCore.initializeFirebase()

export default function App() {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Navigator />
    </Suspense>
  )
}
