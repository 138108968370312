import { getAuth, Auth } from 'firebase/auth'
import { initializeApp, FirebaseApp } from 'firebase/app'

class FirebaseCore {
  private auth: null | Auth = null
  private app: null | FirebaseApp = null

  private config = {
    projectId: process.env.APP_MANIFEST.extra.FIREBASE_PROJECT_ID,
    messagingSenderId: process.env.APP_MANIFEST.extra.FIREBASE_MESSAGE_SENDER_ID,
    storageBucket: process.env.APP_MANIFEST.extra.FIREBASE_STORAGE_BUCKET,
    authDomain: process.env.APP_MANIFEST.extra.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.APP_MANIFEST.extra.FIREBASE_DATABASE_URL,
    apiKey: process.env.APP_MANIFEST.extra.FIREBASE_API_KEY,
    appId: process.env.APP_MANIFEST.extra.FIREBASE_APP_ID,
  }

  /**
   * @description Initialize the firebase app
   */
  initializeFirebase = () => {
    // Initialize the firebase app
    this.app = initializeApp(this.config)
    // Initialize the firebase auth
    this.auth = getAuth(this.app)
  }

  /**
   * @description Get the firebase auth instance
   * @returns the firebase auth instance
   */
  getAuth = () => {
    // Return the firebase auth
    return this.auth
  }
}

export default new FirebaseCore()
