import { gql } from 'graphql-request'

export const ME_QUERY = gql`
  query me {
    me {
      firstName
      lastName
      email
      languages
      knowledge
      profilePicture
      notificationSettings
      finalizedOnboardingAt
      Address {
        address
      }
      firebaseMobilePushToken
      payoutType
      payoutBankRoutingNumber
      payoutBankAccountNumber
      payoutDebitCardNumber
      payoutDebitCardExpiration
      payoutVenmoUsername
      JoinRequests {
        id
        Branch {
          id
          name
          logo
        }
        state
        createdAt
        updatedAt
      }
    }
  }
`

export const GET_TRANSACTIONS = gql`
  query transactions($from: DateTime, $to: DateTime) {
    transactions(from: $from, to: $to) {
      rating
    }
  }
`

export const GET_USER_BRANCHES = gql`
  query me {
    me {
      UserBranches {
        id
        role
        Branch {
          onboardingSlug
          id
          name

          Address {
            address
            addressTwo
            city
            zip
            state
          }
          Pools {
            id
            jurisdiction
            distributionStrategy
            ShiftConfig {
              id
              name
              ShiftGroups {
                id
                daysOfWeek
                Shifts {
                  id
                  name
                  startHours
                  startMinutes
                  endHours
                  endMinutes
                }
              }
            }
          }
          ShortUrl(source: digital) {
            id
            path
            destination
            assets {
              qrBorderRoundedPng
            }
          }
        }
      }
    }
  }
`

export const GET_JOIN_REQUESTS_QUERY = gql`
  query me {
    me {
      UserBranches {
        Branch {
          JoinRequests {
            id
            User {
              firstName
              lastName
              email
            }
            state
            createdAt
            Branch {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const INVITATIONS_QUERY = gql`
  query me {
    me {
      Invitations {
        id
        Inviter {
          firstName
          lastName
          email
        }
        inviteeEmail
        state
        createdAt
        invitedAt
        sentAt
        isExpired
        role
        internalId
        Branch {
          id
          name
        }
      }
    }
  }
`

export const SHORT_URL_QUERY = gql`
  query shortUrl($url: String!) {
    shortUrl(url: $url) {
      id
      source
      path
      destination
    }
  }
`

export const PAYOUTS_QUERY = gql`
  query BranchPayouts($input: BranchPayoutsInput) {
    branchPayouts(input: $input) {
      branchPayouts {
        payoutId
        branchName
        userName
        amount
        creationDate
        state
      }
      totalCount
    }
  }
`

export const TEAM_QUERY = gql`
  query team($id: String!) {
    team(id: $id) {
      id
      name
      internalId
      SharedReceiverProfile {
        id
        profilePicture
        companyName
        Service {
          id
          jobTitle
        }
        ShortUrl(source: digital) {
          assets {
            qrBorderRoundedPng
          }
        }
      }
      Pool {
        id
        distributionStrategy
        ShiftConfig {
          id
          name
          ShiftGroups {
            id
            daysOfWeek
            Shifts {
              id
              name
              startHours
              startMinutes
              endHours
              endMinutes
            }
          }
        }
      }
      Branch {
        Services {
          id
          jobTitle
        }
        name
        UserBranches {
          id
          role
          internalId
          User {
            id
            firstName
            lastName
            email
            profilePicture
            finalizedOnboardingAt
          }
          Branch {
            name
          }
        }
      }
      ReceiverProfiles {
        id
        profilePicture
        Service {
          jobTitle
        }
        internalId
        User {
          finalizedOnboardingAt
          profilePicture
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`

export const GET_TEAMS_FOR_BRANCH_QUERY = gql`
  query branch($input: BranchInput!) {
    branch(input: $input) {
      Teams {
        id
        name
        internalId
        SharedReceiverProfile {
          id
          profilePicture
          companyName
          Service {
            id
            jobTitle
          }
          ShortUrl(source: digital) {
            assets {
              qrBorderRoundedPng
            }
          }
        }
        Pool {
          id
          distributionStrategy
          ShiftConfig {
            id
            name
            ShiftGroups {
              id
              daysOfWeek
              Shifts {
                id
                name
                startHours
                startMinutes
                endHours
                endMinutes
              }
            }
          }
        }
        Branch {
          id
          Services {
            id
            jobTitle
          }
          name
          UserBranches {
            id
            role
            internalId
            User {
              id
              firstName
              lastName
              email
              profilePicture
              finalizedOnboardingAt
            }
            Branch {
              name
            }
          }
        }
        ReceiverProfiles {
          id
          profilePicture
          Service {
            jobTitle
          }
          internalId
          User {
            finalizedOnboardingAt
            profilePicture
            id
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`

export const BRANCH_QUERY = gql`
  query branch($input: BranchInput!) {
    branch(input: $input) {
      id
      name
      allowReceiversCheckPaperBranch
      onboardingSlug
      Address {
        address
        addressTwo
        city
        zip
        state
      }
      UserBranches {
        id
        role
        internalId
        User {
          firstName
          lastName
          email
          profilePicture
          finalizedOnboardingAt
        }
        Branch {
          name
        }
      }
      Services {
        id
        defaultAvgTip
        jobTitle
        poolWeight
      }
      thirdPartyRatingName
      thirdPartyRatingLink
      ShortUrl(source: digital) {
        id
        path
        destination
        assets {
          qrBorderRoundedPng
        }
      }
    }
  }
`

export const SIGN_UP_LINK = gql`
  query SignUpLink($input: SignUpLinkInput) {
    signUpLink(input: $input) {
      title
      role
      valid
    }
  }
`

export const GET_RECEIVER_PROFILE_QUERY = gql`
  query ReceiverProfile($input: String!) {
    receiverProfile(id: $input) {
      id
      Service {
        jobTitle
      }
      User {
        firstName
        lastName
        email
      }
    }
  }
`
