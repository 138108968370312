import { gql } from 'graphql-request'

export const UPDATE_ME_MUTATION = gql`
  mutation UpdateMe($input: UpdateMeInput!) {
    updateMe(input: $input) {
      firstName
      lastName
      email
      profilePicture
      payoutType
    }
  }
`

export const CREATE_INVITATION_MUTATION = gql`
  mutation CreateUserBranchInvitation($input: CreateUserBranchInvitationInput!) {
    createUserBranchInvitation(input: $input) {
      id
      inviteeEmail
      state
      createdAt
      invitedAt
      sentAt
      isExpired
      role
      internalId
    }
  }
`

export const CREATE_JOIN_REQUEST_MUTATION = gql`
  mutation CreateJoinRequest($input: CreateJoinRequestInput!) {
    createJoinRequest(input: $input) {
      id
      state
      createdAt
    }
  }
`

export const ACCEPT_USER_BRANCH_INVITATION_MUTATION = gql`
  mutation AcceptUserBranchInvitation($id: String!) {
    acceptUserBranchInvitation(id: $id) {
      id
      role
    }
  }
`

export const ACCEPT_JOIN_REQUEST_MUTATION = gql`
  mutation AcceptJoinRequest($id: String!) {
    acceptJoinRequest(id: $id) {
      id
      role
    }
  }
`

export const REJECT_USER_BRANCH_INVITATION_MUTATION = gql`
  mutation RejectInvitation($id: String!) {
    rejectInvitation(id: $id) {
      success
    }
  }
`

export const REJECT_JOIN_REQUEST_MUTATION = gql`
  mutation RejectJoinRequest($id: String!) {
    rejectJoinRequest(id: $id) {
      success
    }
  }
`

interface CreateReceiverProfileInput {
  serviceId: string
  userId: string
  teamId: string
}

export const CREATE_RECEIVER_PROFILE_MUTATION = gql`
  mutation CreateReceiverProfile($input: CreateReceiverProfileInput!) {
    createReceiverProfile(input: $input) {
      id
    }
  }
`

export const CREATE_USER_BRANCH_INVITATION_MUTATION = gql`
  mutation CreateUserBranchInvitation($input: CreateUserBranchInvitationInput!) {
    createUserBranchInvitation(input: $input) {
      id
    }
  }
`

export const UPDATE_USER_BRANCH_MUTATION = gql`
  mutation UpdateUserBranch($input: UpdateUserBranchInput!) {
    updateUserBranch(input: $input) {
      internalId
    }
  }
`

export const UPDATE_RECEIVER_PROFILE_MUTATION = gql`
  mutation UpdateReceiverProfile($input: UpdateReceiverProfileInput!) {
    updateReceiverProfile(input: $input) {
      id
    }
  }
`

export const CREATE_TEAM_MUTATION = gql`
  mutation CreateTeam($input: CreateTeamInput!) {
    createTeam(input: $input) {
      id
      name
    }
  }
`

export const CREATE_POOL_MUTATION = gql`
  mutation CreatePool($input: CreatePoolInput!) {
    createPool(input: $input) {
      id
    }
  }
`

export const CREATE_SHARED_RECEIVER_PROFILE_MUTATION = gql`
  mutation CreateSharedReceiverProfile($input: CreateSharedReceiverProfileInput!) {
    createSharedReceiverProfile(input: $input) {
      id
    }
  }
`

export const UPLOAD_SHIFT_DATA_MUTATION = gql`
  mutation UploadShiftData($input: ShiftDataUploadInput!) {
    uploadShiftData(input: $input) {
      totalTipAmount
      undistributedTipAmount
      receiverShares {
        fileHoursWorked
        fileRowsAnalyzed
        tipAmount
        sharesCount
        Receiver {
          id
          internalId
          name
        }
      }
      unlinkedWorkers {
        id
        name
      }
      unlinkedReceivers {
        id
        name
      }
      periodStart
      periodEnd
      wasDryRun
    }
  }
`

export const UPDATE_TEAM_MUTATION = gql`
  mutation UpdateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      id
      name
      internalId
    }
  }
`

export const UPDATE_POOL_MUTATION = gql`
  mutation UpdatePool($input: UpdatePoolInput!) {
    updatePool(input: $input) {
      id
      distributionStrategy
    }
  }
`

export const DELETE_POOL_MUTATION = gql`
  mutation DeletePool($teamId: String!) {
    deletePool(teamId: $teamId) {
      success
    }
  }
`

export const UPDATE_SHARED_RECEIVER_PROFILE_MUTATION = gql`
  mutation UpdateSharedReceiverProfile($input: UpdateSharedReceiverProfileInput!) {
    updateSharedReceiverProfile(input: $input) {
      id
      profilePicture
      companyName
      Service {
        id
        jobTitle
      }
    }
  }
`

export const DELETE_SHARED_RECEIVER_PROFILE_MUTATION = gql`
  mutation DeleteSharedReceiverProfile($teamId: String!) {
    deleteSharedReceiverProfile(teamId: $teamId) {
      success
    }
  }
`

export const DELETE_RECEIVER_PROFILE_MUTATION = gql`
  mutation DeleteReceiverProfile($id: String!) {
    deleteReceiverProfile(id: $id) {
      success
    }
  }
`

export const CREATE_SHIFT_CONFIG = gql`
  mutation CreateShiftConfig($input: CreateShiftConfigInput!) {
    createShiftConfig(input: $input) {
      id
    }
  }
`

export const UPDATE_SHIFT_CONFIG = gql`
  mutation UpdateShiftConfig($input: UpdateShiftConfigInput!) {
    updateShiftConfig(input: $input) {
      id
    }
  }
`
