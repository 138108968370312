// Import the emittify module.
import Emittify from '@colorfy-software/emittify/dist/react'
import { ShiftConfig, ShiftGroup } from '../graphql/graphql'

import { InvitationType } from '../types/api-types'
import { JoinRequestType, UserBranch, UserType } from '../types/user-types'

// Type for the emitter key is the name of the event and value is the type of the event.
interface EventsType {
  'user-data': UserType
  branches: UserBranch[]
  'notification-menu': 'open' | 'close'
  invitations: (InvitationType | JoinRequestType)[]
  'navigation-stack': 'AppStack' | 'AuthStack'
  'show-location-qr': { url: string; branchName: string } | false
  'show-generic-qr': { url: string; team: string } | false
  'show-shift-config': {
    branchId: string
    poolId: string
    shiftGroups?: ShiftGroup[]
  } | null
  'update-shift-config': {
    poolId: string
    branchId: string
    shiftConfig?: ShiftConfig
  } | null
  'get-team': undefined
}

const emitter = new Emittify<EventsType>({
  // Cache is used to cache the events and prevent emitting the same event multiple times
  cachedEvents: ['navigation-stack', 'user-data', 'notification-menu', 'branches', 'invitations'],
})

export default emitter
