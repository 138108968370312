import React from 'react'
import { ActivityIndicator, View, StyleSheet } from 'react-native'

import GradientBackground from '../components/GradientBackground'
import colors from '../utils/colors'

interface LoadingScreenPropsType {
  noGradient?: boolean
}

const LoadingScreen = (props: LoadingScreenPropsType) => {
  if (props.noGradient) {
    return (
      <View style={styles.container}>
        <ActivityIndicator size="large" color={colors.colors.text} />
      </View>
    )
  }

  return (
    <GradientBackground>
      <View style={styles.container}>
        <ActivityIndicator size="large" color="white" />
      </View>
    </GradientBackground>
  )
}

export default LoadingScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
